<template>
  <component
    :is="dynamicComponent"
    v-bind="componentProps"
    class="AppSpinner"
  />
</template>

<script setup>
  import { computed, ref, watch, toRefs } from 'vue';
  import { AtomSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';

  defineOptions({
    name: 'AppSpinner'
  })

  const props = defineProps({
    component: {
      type: String,
      default: 'AtomSpinner'
    },
    animationDuration: {
      type: Number,
      default: 1500
    },
    color: {
      type: String,
      default: '#c30a14'
    },
    size: {
      type: Number,
      default: 16
    }
  });

  const { component, animationDuration, color, size } = toRefs(props);

  const dynamicComponent = computed(() => {
    switch (component.value) {
      case 'AtomSpinner':
        return AtomSpinner;
      case 'SelfBuildingSquareSpinner':
        return SelfBuildingSquareSpinner;
      default:
        return AtomSpinner;
    }
  });

  const componentProps = ref({
    animationDuration: animationDuration.value,
    color: color.value,
    size: size.value
  });

  watch([animationDuration, color, size], () => {
    componentProps.value = {
      animationDuration: animationDuration.value,
      color: color.value,
      size: size.value
    };
  });
</script>
