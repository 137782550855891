<template>
  <ul class="AppPagination">
    <li class="pagination_prev">
      <a
        class="item_link"
        :class="[{ 'disabled': !hasPrev }]"
        @click.prevent="onPrev"
      >
        <app-icon name="arrow-left" />
      </a>
    </li>

    <li class="pagination_size_indicator">
      <p>
        {{ range[0] }} - {{ range[1] }} <span>{{ $t('account.pagination.of', 'of') }}</span> {{ total }}
      </p>
    </li>

    <li class="pagination_next">
      <a
        class="item_link"
        :class="[{ 'disabled': !hasNext }]"
        @click.prevent="onNext"
      >
        <app-icon name="arrow-left" />
      </a>
    </li>
  </ul>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { clamp } from 'ramda';
  import AppIcon from '@/components/global/AppIcon.vue';

  const props = defineProps({
    total: {
      type: Number,
      default: 0,
      validator: val => {
        return val >= 0
      }
    },
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1,
      validator: val => {
        return val > 0
      }
    }
  });

  const emit = defineEmits(['onPrev', 'onNext']);

  const maxPages = computed(() => {
    const calcPages = Math.floor((props.total - 1) / props.pageSize) + 1
    return calcPages > 0 ? calcPages : 1
  });

  const initCurrent = computed(() => {
    return props.total > 0 ? clamp(1, maxPages.value, props.currentPage) : 0
  });

  const range = computed(() => {
    return [
      props.total > 0 ? (props.currentPage - 1) * props.pageSize + 1 : 0,
      props.currentPage * props.pageSize > props.total ? props.total : props.currentPage * props.pageSize
    ];
  });

  const hasPrev = computed(() => {
    return props.currentPage > 1
  });

  const hasNext = computed(() => {
    return props.currentPage > 0 && props.currentPage < maxPages.value
  });

  const onPrev = () => {
    if (hasPrev.value) {
      emit('onPrev');
    }
  };

  const onNext = () => {
    if (hasNext.value) {
      emit('onNext');
    }
  };
</script>

<style lang="scss">
  .AppPagination {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .14rem;
    line-height: .16rem;
    color: $grey-500;
    user-select: none;

    .pagination_prev {
      margin-right: .02rem;
    }

    .pagination_next {
      margin-left: .02rem;

      .icon-arrow-left {
        transform: rotate(180deg);
      }
    }

    .pagination_prev,
    .pagination_next {
      .item_link {
        width: .31rem;
      }
    }

    .item_link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: .4rem;
      width: .4rem;
      border-radius: 50%;

      &:hover {
        color: $primary;
      }

      &.disabled {
        opacity: .5;
        cursor: not-allowed;

        &:hover {
          color: $grey-500 !important;
        }
      }

      .icon-arrow-left {
        width: .07rem;
        height: .14rem;
      }
    }

    .pagination_size_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 .12rem;

      @include media (tablet) {
        order: -1;
        margin-right: .06rem;
      }
    }
  }
</style>
