import { api } from '@/api/backend/index';
import store from 'store';
import { extractErrorMessage } from '@/utilities/index';
import { showNotification } from '@/utilities/notifications/notificationHandler';
import CustomerTypes from '@/enums/CustomerTypes';
import PackageTypes from '@/enums/PackageTypes';

const state = () => ({
  summary: null,
  pendingFutureActiveSubscription: null,
  isSummaryLoading: false,
  alternatives: [],
  isAlternativesLodaing: false,
  comparisonInfo: [],
  isComparisonInfoLoading: false
})

const mutations = {
  setSummary (state, summary) {
    state.summary = summary;
  },

  setPendingFutureActiveSummary (state, pendingActiveSummary) {
    state.pendingFutureActiveSubscription = pendingActiveSummary;
  },

  setIsSummaryLoading (state, isSummaryLoading) {
    state.isSummaryLoading = isSummaryLoading;
  },

  setAlternatives (state, alternatives) {
    state.alternatives = alternatives;
  },

  setIsAlternativesLodaing (state, isAlternativesLodaing) {
    state.isAlternativesLodaing = isAlternativesLodaing;
  },

  setComparisonInfo (state, comparisonInfo) {
    state.comparisonInfo = comparisonInfo;
  },

  setIsComparisonInfoLoading (state, isComparisonInfoLoading) {
    state.isComparisonInfoLoading = isComparisonInfoLoading;
  }
}

const actions = {
  async fetchSummary ({ commit }, $prismic) {
    try {
      commit('setIsSummaryLoading', true);
      const summary = await api.subscription.getSummary();
      const pendingActiveSubscription = summary.pendingFutureActiveSubscription;
      const packages = await $prismic.client.get(
        $prismic.filter.at('document.type', 'package'),
        { lang: store.state.locale.localeCode }
      );

      const customerInfo = await api.account.getInformation();
      let packageResults = packages.results.map(pkg => pkg.data || {});
      if (customerInfo.customerType === CustomerTypes.NEW) {
        packageResults = packageResults.filter(result => result?.name?.toLowerCase() !== PackageTypes.HORIZON_BASIC.toLowerCase());
      }
      const activePackage = packageResults.find(result => result.sku === summary.sku);

      let normalizedSummary;
      let pendingFutureSummary = null;

      if (pendingActiveSubscription) {
        const pendingPackage = packageResults.find(result => result.sku === pendingActiveSubscription.sku);
        if (pendingPackage) {
          pendingFutureSummary = Object.assign({}, pendingActiveSubscription, { description: pendingPackage.description, features: pendingPackage.features, packages: packageResults });
        }
      }

      if (activePackage) {
        normalizedSummary = Object.assign({}, summary, { description: activePackage.description, features: activePackage.features, packages: packageResults });
      } else {
        normalizedSummary = Object.assign({}, summary, { description: '', features: [] });
      }

      commit('setSummary', normalizedSummary);
      commit('setPendingFutureActiveSummary', pendingFutureSummary);
    } catch (error) {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    } finally {
      commit('setIsSummaryLoading', false);
    }
  },

  async fetchAlternatives ({ commit }, $prismic) {
    try {
      commit('setIsAlternativesLodaing', true)
      const alternativePackages = await api.subscription.getAlternatives();
      const alternativePackagesSku = alternativePackages.map(item => item.sku);

      const { results } = await $prismic.client.get(
        $prismic.filter.any('my.package.sku', alternativePackagesSku),
        { lang: store.state.locale.localeCode }
      );

      const normalizedPackages = alternativePackages.map(item => {
        const packageConfigure = results.find(connfigItem => connfigItem.data.sku === item.sku);
        return {
          ...item,
          shortDescritpion: (packageConfigure && packageConfigure.data.short_description) || ''
        };
      })

      commit('setAlternatives', normalizedPackages);
      
    } catch (error) {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    } finally {
      commit('setIsAlternativesLodaing', false);
    }
  },

  async fetchComparisonInfo ({ commit }, params) {
    const { newProductId, $prismic } = params;
    try {
      commit('setIsComparisonInfoLoading', true);
      const comparisonInfo = await api.subscription.getComparison(newProductId);
      const packageSkus = comparisonInfo.map(item => item.sku);
      const { results } = await $prismic.client.get(
        $prismic.filter.any('my.package.sku', packageSkus),
        { lang: store.state.locale.localeCode }
      );
      const packages = await $prismic.client.get(
        $prismic.filter.at('document.type', 'package'),
        { lang: store.state.locale.localeCode }
      );
      const customerInfo = await api.account.getInformation();
      let packageResults = packages.results.map(pkg => pkg.data);
      if (customerInfo.customerType === CustomerTypes.NEW) {
        packageResults = packageResults.filter(result => result.name.toLowerCase() !== PackageTypes.HORIZON_BASIC.toLowerCase())
      }

      const normalizedComparisonInfo = comparisonInfo.map(item => {
        const packageConfigure = results.find(connfigItem => connfigItem.data.sku === item.sku)
        return {
          ...item,
          description: (packageConfigure && packageConfigure.data.description) || '',
          features: (packageConfigure && packageConfigure.data.features) || [],
          packages: packageResults
        }
      });
      commit('setComparisonInfo', normalizedComparisonInfo);
    } catch (error) {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    } finally {
      commit('setIsComparisonInfoLoading', false);
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
