import AdyenCheckout from '@adyen/adyen-web';

export default {
    install(app) {
        const configuration = {
            environment: process.env.VUE_APP_ADYEN_ENV,
            clientKey: process.env.VUE_APP_ADYEN_KEY
        };
        app.config.globalProperties.$adyen = new AdyenCheckout(configuration);
        app.config.globalProperties.$adyenConfig = configuration;
    }
};