import { extractErrorMessage } from 'utilities/index';
import { showNotification } from '@/utilities/notifications/notificationHandler';
import creditMemoApis from '@/api/backend/resources/creditMemo';
import { instance } from '@/api/backend/index';

const state = () => ({
  creditMemoList: null,
  isCreditMemoLoading: false,
  pagination: {
    page: undefined,
    pageSize: undefined,
    totalCount: undefined
  }
})

const mutations = {
  setCreditMemoList (state, data) {
    state.creditMemoList = data
  },
  setIsCreditMemoLoading (state, status) {
    state.isCreditMemoLoading = status
  },
  setCreditMemoPagination (state, data) {
    state.pagination = data
  }
}

const creditMemoRequests = creditMemoApis(instance);

const actions = {
  fetchCreditMemoList ({ commit }, pagination) {
    commit('setIsCreditMemoLoading', true);
    creditMemoRequests.getCreditMemoData(pagination).then(response => {
      if (response?.data && response?.pagination) {
        commit('setCreditMemoList', response.data)
        commit('setCreditMemoPagination', response.pagination)
        commit('setIsCreditMemoLoading', false)
      }
    }).catch(error => {
      commit('setIsCreditMemoLoading', false)
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      })
    })
  },
  resetCreditMemoList( { commit }) {
    commit('setCreditMemoList', null);
    commit('setCreditMemoPagination', {page: undefined, pageSize: undefined, totalCount: undefined});
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
