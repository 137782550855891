<template>
  <div class="footer">
    <menu class="footer-menu">
      <div class="menu-item">
        <app-locale-switcher />
      </div>
      <slot :link="getLink">
        <app-dropdown-button :title="$t('common.footer.agreement', 'User agreement')" class="menu-item">
          <a :href="getLink('north_america')" target="_blank">{{ $t('common.footer.agreement.north_america', 'USA') }}</a>
          <a :href="getLink('europe')" target="_blank">{{ $t('common.footer.agreement.europe', 'Europe') }}</a>
          <a :href="getLink('canada')" target="_blank">{{ $t('common.footer.agreement.canada', 'Canada') }}</a>
        </app-dropdown-button>
        <a href="https://communications.lely.com/horizon" target="_blank" class="menu-item">{{ $t('common.footer.faq', "FAQ's") }}</a>
      </slot>
    </menu>
  </div>
</template>
<script setup>
  import { computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { usePrismic } from "@prismicio/vue";
  import AppLocaleSwitcher from '@/components/global/AppLocaleSwitcher.vue';
  import AppDropdownButton from '@/components/global/AppDropdownButton.vue';

  const store = useStore();
  const { client } = usePrismic();
  const localeCode = computed(() => store.state.locale.localeCode);
  const pdfLinks = computed(() => store.getters['locale/pdfLinks']);

  onMounted(() => {
    store.dispatch('locale/fetchUALinks', client);
  });

  const getLink = (type) => {
    if (type && (pdfLinks.value[localeCode.value] || pdfLinks.value['en-us'])) {
      return pdfLinks.value[localeCode.value] ? pdfLinks.value[localeCode.value][type] : pdfLinks.value['en-us'][type]
    }
    return '#'
  }
</script>

<style lang="scss">
  .footer {
    border-top: 1px solid #f7f6f4;

    .footer-menu {
      display: flex;
      padding: 0;
      flex-direction: column;

      @include media(tablet) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 2.8rem;
        width: var(--footer-width, unset);
      }

      .menu-item {
        font-size: .12rem;
        line-height: .18rem;
        text-decoration-line: underline;
        color: var(--footer-color, #fff);
        margin-bottom: .24rem;

        @include media(tablet) {
          margin-bottom: unset;
        }
      }
    }
  }
</style>
