<template>
  <span class="AppBreadcrumbItem">
    <span :class="[{ 'item_link': to }]" @click="onClick">
      <slot></slot>
    </span>

    <span v-if="injectedSeparator || slots.separator" class="item__separator">
      <slot name="separator">
        {{ injectedSeparator }}
      </slot>
    </span>
  </span>
</template>

<script setup>
  import { inject, useSlots } from 'vue';
  import { useRouter } from 'vue-router';

  const props = defineProps({
    to: {
      type: [Object, String],
      default: ''
    }
  });

  const injectedSeparator = inject('separator', '/');
  const slots = useSlots();
  const router = useRouter();

  const onClick = () => {
    if (props.to) {
      router.push(props.to);
    }
  };
</script>

<style lang="scss">
  .AppBreadcrumbItem {
    display: inline-flex;
    align-items: center;

    .item_link {
      cursor: pointer;
      text-decoration: underline;
      display: inline-block;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }

    .item__separator {
      margin: 0 .18rem;
      display: inline-block;
      font-size: .14rem;
      color: $grey-300;
    }
  }
</style>
