<template>
  <div class="AppLocaleSwitcher">
    <app-select
      :value="currentLocale"
      :options="locales"
      track-by="code"
      track-label="name"
      open-direction="top"
      @update:modelValue="switchLocale"
    />
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const locales = computed(() => store.state.locale.locales);
  const localeCode = computed(() => store.state.locale.localeCode);
  const currentLocale = computed(() => store.getters['locale/currentLocale']);

  const switchLocale = (locale) => {
    if (locale.code !== localeCode.value) {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.replace(localeCode.value, locale.code);
      window.location.href = newUrl;
    }
  }

</script>

<style lang="scss">

  .AppLocaleSwitcher {
    min-width: 1rem;

    .AppSelect {
      .multiselect {
        min-height: unset;

        &__tags {
          padding: 0;
          color: #333;
          border: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 0;
          background: transparent;
          min-height: unset;
        }

        &__content-wrapper {
          border-radius: 0;
          overflow-x: hidden;
        }

        &__option {
          padding: .1rem;
          font-size: .12rem;
          line-height: .18rem;
          white-space: nowrap;
        }

        &__input,
        &__single {
          background: transparent;
          color: var(--footer-color, #fff);
          font-size: .12rem;
          line-height: .18rem;
        }

        &__single {
          text-decoration: underline;
          font-weight: 500;
        }

        &__select {
          right: 0;
          background: no-repeat center  var(--footer-select-icon, url('~assets/images/triangle_white.svg'));
        }
      }
    }
  }
</style>
