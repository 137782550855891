import { extractErrorMessage } from 'utilities/index';
import { instance } from '@/api/backend/index';
import creditApis from '@/api/backend/resources/credit';
import { showNotification } from '@/utilities/notifications/notificationHandler';

const state = () => ({
  credit: null,
  isCreditLoading: false
})

const mutations = {
  setCredit (state, data) {
    state.credit = data
  },
  setIsCreditLoading (state, status) {
    state.isCreditLoading = status
  }
}

const creditRequests = creditApis(instance);

const actions = {
  fetchCredit ({ commit }) {
    commit('setIsCreditLoading', true);
    creditRequests.getCredit().then(data => {
      if (data) {
        commit('setCredit', data);
      }
    }).catch(error => {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    }).finally(() => {
      commit('setIsCreditLoading', false);
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
