export const countries = [{
    code: 'NL',
    codeA3: 'NLD',
    name: 'The Netherlands'
  },
  {
    code: 'US',
    codeA3: 'USA',
    name: 'United States'
  },
  {
    code: 'BE',
    codeA3: 'BEL',
    name: 'Belgium'
  },
  {
    code: 'GB',
    codeA3: 'GBR',
    name: 'United Kingdom'
  },
  {
    code: 'IE',
    codeA3: 'IRL',
    name: 'Ireland'
  },
  {
    code: 'CA',
    codeA3: 'CAN',
    name: 'Canada'
  },
  {
    code: 'AU',
    codeA3: 'AUS',
    name: 'Australia'
  },
  {
    code: 'NZ',
    codeA3: 'NZL',
    name: 'New Zealand'
  },
  {
    code: 'FR',
    codeA3: 'FRA',
    name: 'France'
  },
  {
    code: 'CH',
    codeA3: 'CHE',
    name: 'Switzerland'
  },
  {
    code: 'LU',
    codeA3: 'LUX',
    name: 'Luxembourg'
  },
  {
    code: 'DE',
    codeA3: 'DEU',
    name: 'Germany'
  },
  {
    code: 'AT',
    codeA3: 'AUT',
    name: 'Austria'
  },
  {
    code: 'LI',
    codeA3: 'LIE',
    name: 'Liechtenstein'
  },
  {
    code: 'NO',
    codeA3: 'NOR',
    name: 'Norway'
  },
  {
    code: 'SE',
    codeA3: 'SWE',
    name: 'Sweden'
  },
  {
    code: 'FI',
    codeA3: 'FIN',
    name: 'Finland'
  },
  {
    code: 'DK',
    codeA3: 'DNK',
    name: 'Denmark'
  },
  {
    code: 'ES',
    codeA3: 'ESP',
    name: 'Spain'
  },
  {
    code: 'IT',
    codeA3: 'ITA',
    name: 'Italy'
  },
  {
    code: 'JP',
    codeA3: 'JPN',
    name: 'Japan'
  },
  {
    code: 'IS',
    codeA3: 'ISL',
    name: 'Iceland'
  },
  {
    code: 'PL',
    codeA3: 'POL',
    name: 'Poland'
  },
  {
    code: 'CZ',
    codeA3: 'CZE',
    name: 'Czech Republic'
  },
  {
    code: 'KR',
    codeA3: 'KOR',
    name: 'Korea, Republic of'
  },
  {
    code: 'KP',
    codeA3: 'PRK',
    name: 'Korea (North)'
  },
  {
    code: 'IL',
    codeA3: 'ISR',
    name: 'Israel'
  },
  {
    code: 'PT',
    codeA3: 'PRT',
    name: 'Portugal'
  },
  {
    code: 'EE',
    codeA3: 'EST',
    name: 'Estonia'
  },
  {
    code: 'RU',
    codeA3: 'RUS',
    name: 'Russian Federation'
  },
  {
    code: 'SI',
    codeA3: 'SVN',
    name: 'Slovenia'
  },
  {
    code: 'SK',
    codeA3: 'SVK',
    name: 'Slovakia'
  },
  {
    code: 'LT',
    codeA3: 'LTU',
    name: 'Lithuania'
  },
  {
    code: 'TR',
    codeA3: 'TUR',
    name: 'Turkey'
  },
  {
    code: 'LV',
    codeA3: 'LVA',
    name: 'Latvia'
  },
  {
    code: 'UA',
    codeA3: 'UKR',
    name: 'Ukraine'
  },
  {
    code: 'CN',
    codeA3: 'CHN',
    name: 'China'
  },
  {
    code: 'BY',
    codeA3: 'BLR',
    name: 'Belarus'
  },
  {
    code: 'GR',
    codeA3: 'GRC',
    name: 'Greece'
  },
  {
    code: 'IR',
    codeA3: 'IRN',
    name: 'Iran'
  },
  {
    code: 'HR',
    codeA3: 'HRV',
    name: 'Croatia'
  },
  {
    code: 'HU',
    codeA3: 'HUN',
    name: 'Hungary'
  },
  {
    code: 'RO',
    codeA3: 'ROU',
    name: 'Romania'
  },
  {
    code: 'TH',
    codeA3: 'THA',
    name: 'Thailand'
  },
  {
    code: 'BG',
    codeA3: 'BGR',
    name: 'Bulgaria'
  },
  {
    code: 'CY',
    codeA3: 'CYP',
    name: 'Cyprus'
  },
  {
    code: 'MT',
    codeA3: 'MLT',
    name: 'Malta'
  }
]

export const europe = [
  'AT', 
  'BE', 
  'CZ', 
  'DK', 
  'DE', 
  'EE', 
  'ES', 
  'FI', 
  'FR', 
  'HU', 
  'IE', 
  'IT', 
  'LU', 
  'LT', 
  'LV', 
  'PL', 
  'PT', 
  'SK', 
  'SE'
];

export const mapCountryName = (code) => {
  return countries.find(item => item.code === code).name
}

export const mapCountryCodeA3 = (code) => {
  return countries.find(item => item.code === code).codeA3
}
