import VueCookies from 'vue-cookies';

const state = () => ({
  isAuthenticated: localStorage.getItem('isAuthenticated') || false
})

const getters = {
  isAuthenticated: state => state.isAuthenticated
}

const mutations = {
  setIsAuthenticated (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  }
}

const actions = {
  acquireToken ($auth) {
    $auth.acquireToken()
  },

  setAuthStatus ({ commit, getters, state }) {
    // TODO find a better way of checking for authenticated user
    try {
      const isAuthenticated = VueCookies.get('x-lely:authenticated') || 'true';
      commit('setIsAuthenticated', isAuthenticated)
    } catch (error) {
      console.error(error)
    } finally {
      if (getters.isAuthenticated) {
        localStorage.setItem('isAuthenticated', state.isAuthenticated)
      }
    }
  },

  logout ({ commit }, $auth) {
    // commit('deleteAccessToken')
    localStorage.removeItem('trackData')
    $auth.logout()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
