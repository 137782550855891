import { getRouterInstance, getStoreInstance } from '@/utilities/helpers/vueInstanceHelper.js';

export const handleLogout = () => {
  const store = getStoreInstance();
  const router = getRouterInstance();

  store.commit('user/setIsAuthenticated', false);
  localStorage.setItem('isAuthenticated', 'false');

  if (router.currentRoute.name !== 'loading') {
    router.replace({
      name: 'loading',
      query: {
        redirect: router.currentRoute.fullPath,
      },
    });
  }
}
