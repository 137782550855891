<template>
  <div class="AppBreadcrumb">
    <app-button
      v-if="backRoute"
      theme="default"
      class="router_back_btn"
      @click="onClick"
    >
      <app-icon name="arrow-left" />
    </app-button>

    <slot></slot>
  </div>
</template>

<script setup>
  import { provide } from 'vue';
  import { useRouter } from 'vue-router';

  defineOptions({
    name: 'AppBreadcrumb'
  });

  const props = defineProps({
    separator: {
      type: String,
      default: '/'
    },
    backRoute: {
      type: [Object, String],
      default: null
    }
  });

  const router = useRouter();

  const onClick = () => {
    router.push(props.backRoute);
  }

  provide('separator', props.separator);
</script>

<style lang="scss">
  .AppBreadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 300;
    font-size: .26rem;
    line-height: .4rem;

    .router_back_btn {
      margin-right: .2rem;

      .icon-arrow-left {
        width: .08rem;
        height: .14rem;
      }
    }

    .AppBreadcrumbItem:last-of-type {
      .item__separator {
        display: none;
      }
    }
  }
</style>
