export default function (instance) {
  return {
    getCreditMemoData (pagination) {
      return instance
        .get('/api/invoices/customer/creditMemos', {
          params: {
            ...pagination
          }
        })
        .then(response => response.data)
    }
  }
}
