export default function (instance) {
    return {
      requestAssistance () {
        return instance
          .post('/api/subscriptions/farm-scan/get-assistance', {})
          .then(response => response)
      },

      orderNow (licenseKey) {
        return instance
          .post('/api/subscriptions/order/farm-scan', {
            licenseKey: licenseKey
          })
          .then(response => response)
      }
    }
  }
