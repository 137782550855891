export default function (instance) {
  return {
    getInformation (resellerAccountNumber) {
      return instance
        .get('/api/reseller', {
          params: {
            resellerAccountNumber
          }
        })
        .then(response => response.data)
    }
  }
}
