export default function (instance) {
  return {
    getInformation () {
      return instance
        .get('/api/customer')
        .then(response => { 
          return response.data
        })
    },

    updateInformation (personalInformation) {
      return instance
        .post('/api/customer', personalInformation)
        .then(response => response.data || response)
    },

    getOnboardingOverview () {
      return instance
        .get('/api/customer/onboarding-overview')
        .then(response => response.data)
    },

    validateVatNumber ({ countryCode, vatNumber }) {
      return instance
        .post('/api/customer/vat-validation', {
          countryCode,
          vatNumber
        })
        .then(response => response.data)
    },

    activateSubscriptions () {
      return instance
        .post('/api/customer/subscriptions/activate')
        .then(response => response.data)
    }
  }
}
