<template>
  <div
    class="AppBlank"
  >
    <img
      v-if="image"
      :src="image.src"
      :alt="image.alt"
      class="alert-icon"
    >
    <h6 class="title">
      {{ title }}
    </h6>
    <p class="subtitle">
      {{ subtitle }}
    </p>
    <slot name="action"></slot>
  </div>
</template>

<script setup>
  const props = defineProps({
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: null
    }
  });

  const { title, subtitle, image } = props;
</script>

<style lang="scss">
  .AppBlank {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    color: #766e68;
    margin-top: .72rem;

    @include media (tablet) {
      margin-top: .86rem;
    }

    @include media (desktop) {
      margin-top: 1.39rem;
    }

    .alert-icon {
      margin-bottom: .24rem;
    }

    .title {
      font-weight: bold;
      text-align: center;
      font-size: .14rem;
      line-height: .24rem;
    }

    .subtitle {
      font-size: .14rem;
      line-height: .24rem;
      text-align: justify;
      max-width: 2.92rem;
      overflow-wrap: break-word;
    }
  }
</style>
