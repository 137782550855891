export const adyenLocale = [
    'zh-CN',
    'zh-TW',
    'hr-HR',
    'cs-CZ',
    'da-DK',
    'nl-NL',
    'en-US',
    'fi-FI',
    'fr-FR',
    'de-DE',
    'el-GR',
    'hu-HU',
    'it-IT',
    'ja-JP',
    'ko-KR',
    'no-NO',
    'pl-PL',
    'pt-BR',
    'ro-RO',
    'ru-RU',
    'sk-SK',
    'sl-SI',
    'es-ES',
    'sv-SE'
  ];