<template>
  <div
    class="AppStep"
    :class="[
      { 'is-vertical': props.direction === Direction.Vertical },
      { 'is-clickable': isClickable }
    ]"
    :style="stepStyle"
    @click="onClick"
  >
    <div class="AppStep_indicator-wrap">
      <div
        class="AppStep_indicator"
        :class="[`${currentStatus}`]"
      >
        <div
          v-if="currentStatus !== 'success' && currentStatus !== 'error'"
          class="inner_text"
        >
          <div class="counter">
            {{ props.itemIndex + 1 }}
          </div>
        </div>

        <app-icon
          v-else
          class="inner_icon"
          :name="currentStatus === 'success' ? 'tick' : 'cross'"
        />
      </div>

      <div
        class="AppStep_process-line"
        :class="[
          props.direction === Direction.Vertical ? 'is-vertical' : 'is-horizontal',
          { 'is-first': props.itemIndex === 0 },
          { 'is-last': props.itemIndex === props.steps.length - 1 },
          { 'is-active': currentStatus !== 'wait' && currentStatus !== 'error' }
        ]"
      >
      </div>
    </div>

    <div
      v-if="props.showDescription && (title || $slots.default)"
      class="AppStep_description"
    >
      <slot>
        <p class="AppStep_description_title text-ellipsis">
          {{ title }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Direction, Directions } from '@/config/constants/checkoutConstants';

defineOptions({
  name: 'AppStep'
});

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  status: {
    type: String,
    default: ''
  },
  showDescription: {
    type: Boolean,
    default: true
  },
  direction: {
    type: String,
    default: Direction.Vertical,
    validator: val => Directions.includes(val),
  },
  space: {
    type: [Number, String],
    default: 70,
  },
  itemIndex: {
    type: Number,
    required: true,
    default: 0
  },
  activeItemIndex: {
    type: Number,
    default: 0,
  },
  clickable: {
    type: Boolean,
    default: false
  },
  steps: {
    type: Object,
    required: true
  }
});

const stepsCount = computed(() => props.steps.length);
const currentStatus = computed(() => props.status || internalStatus.value);

const stepStyle = computed(() => {
  if (props.direction === Direction.Vertical) {
    return { height: `${props.space}px` };
  } else {
    return { width: `${100 / stepsCount.value}%` };
  }
});

const isClickable = computed(() => props.itemIndex > 0 && props.clickable && currentStatus.value === 'success');
const internalStatus = computed(() => {
  if(props.activeItemIndex > props.itemIndex){
    return 'success';
  } else if(props.activeItemIndex === props.itemIndex){
    return 'process';
  } else {
    return 'wait';
  }
})

const onClick = () => {
  if (isClickable.value) {
    emit('click');
  }
}
</script>

<style lang="scss">
  .AppStep {
    &.is-clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &.is-vertical {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .AppStep_indicator-wrap {
        height: 100%;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .AppStep_description {
        width: auto;
        overflow: hidden;
        flex: 1 1 0%;
        text-align: left;
        padding: .06rem .12rem;
      }
    }

    &_indicator-wrap {
      position: relative;
      width: 100%;
    }

    &_indicator {
      position: relative;
      z-index: 3;
      background-color: $grey-300;
      box-shadow: 0 1px 10px rgba(51, 51, 51, .15);
      border-radius: 50%;
      width: .3rem;
      height: .3rem;
      padding: .03rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .inner_text {
        padding: .04rem;
        border-radius: 50%;

        .counter {
          width: .16rem;
          height: .16rem;
          border-radius: 50%;
          font-size: .1rem;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $red-dark;
        }
      }

      .inner_icon {
        color: $red-dark;

        &.icon-tick {
          width: .15rem;
          height: .12rem;
        }

        &.icon-cross {
          width: .12rem;
          height: .12rem;
        }
      }

      &.process,
      &.finish,
      &.success,
      &.error {
        background-color: $white;
      }

      &.process {
        .inner_text {
          border: 1px solid $red-dark;

          .counter {
            color: $white;
            background-color: $red-dark;
          }
        }
      }

      &.finish {
        .inner_text {
          background-color: $red-dark;

          .counter {
            color: $white;
          }
        }
      }
    }

    &_process-line {
      position: absolute;
      z-index: 0;
      background-color: rgba(255, 255, 255, .4);

      &.is-horizontal {
        top: 50%;
        left: 0;
        right: 0;
        height: .02rem;
        transform: translateY(-50%);

        &.is-first {
          left: 50%;
        }

        &.is-last {
          right: 50%;
        }
      }

      &.is-vertical {
        top: 0;
        left: 50%;
        bottom: 0;
        width: .02rem;
        transform: translateX(-50%);

        &.is-first {
          top: 50%;
        }

        &.is-last {
          bottom: 50%;
        }
      }

      &.is-active {
        background-color: $red-dark;
      }
    }

    &_description {
      width: 100%;
      text-align: center;
      padding: .1rem .04rem 0;

      &_title {
        color: $white;
        font-size: $font-size-base;
      }
    }
  }
</style>
