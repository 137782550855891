import axios from 'axios';
import resources from './resources';
import { authInterceptor } from './interceptors.js';
import { mock } from './mock';
import { handleLogout } from '@/utilities/auth';

export const instance = axios.create({
  baseURL: process.env.API_ENDPOINT || process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(authInterceptor);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status && error.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error.response);
  }
)

const getResources = (option, app) => {
  if (option && option.mock) {
    mock(instance);
  }

  const apiResources = Object.entries(resources).reduce((acc, [key, factory]) => {
    acc[key] = factory(instance)
    return acc
  }, {});

  return apiResources;
}

export const api = getResources();

export default {
  install(app, options) {
    const api = getResources(options, app);
    app.config.globalProperties.$api = api;
  },
};
