import { reactive } from 'vue';

export const eventBus = reactive({
    manualStep: false,
    setManualStep(step) {
      this.manualStep = step;
    },
    listeners: {},
    $emit(event, ...args) {
      if (this.listeners[event]) {
        this.listeners[event].forEach((callback) => {
          callback(...args);
        });
      }
    },
    $on(event, callback) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }
      this.listeners[event].push(callback);
    },
    $off(event, callback) {
      if (this.listeners[event]) {
        const index = this.listeners[event].indexOf(callback);
        if (index > -1) {
          this.listeners[event].splice(index, 1);
        }
      }
    }
  });