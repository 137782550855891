import { createStore } from 'vuex';
import user from './modules/user'
import profile from './modules/profile'
import invoices from './modules/invoices'
import credit from './modules/credit'
import locale from './modules/locale'
import reseller from './modules/reseller'
import subscription from './modules/subscription'
import creditMemo from './modules/creditMemo'

const store = createStore({
  modules: {
    user,
    profile,
    invoices,
    credit,
    locale,
    reseller,
    subscription,
    creditMemo
  }
});

export default store;
