import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/config/constants/routes';
import { setRouterInstance, setStoreInstance } from '@/utilities/helpers/vueInstanceHelper';

export function createAppRouter(store) {
  const router = createRouter({
    history: createWebHistory(store.state.locale.localeCode),
    routes
  });

  setStoreInstance(store);
  setRouterInstance(router);

  router.beforeEach((to, from, next) => {
    const { isAuthenticated } = store.state.user;

    if (!isAuthenticated && to.meta.requiresAuth !== false) {
      next({
        name: 'loading',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  });

  return router;
}
