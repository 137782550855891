import { api } from '@/api/backend/index';
import { showNotification } from '@/utilities/notifications/notificationHandler';
import { extractErrorMessage } from 'utilities/index'

const state = () => ({
  resellerInfo: null,
  isLoading: true
})

const mutations = {
  setResellerInfo (state, resellerInfo) {
    state.resellerInfo = resellerInfo;
  },

  setIsLoading (state, isLoading) {
    state.isLoading = isLoading;
  }
}

const actions = {
  async fetchResellerInfo ({ commit }, resellerAccountNumber) {
    try {
      const resellerInfo = await api.reseller.getInformation(resellerAccountNumber);
      commit('setResellerInfo', resellerInfo);
      return resellerInfo;
    } catch (error) {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    } finally {
      commit('setIsLoading', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
