<template>
  <div class="Form_group" :class="{ 'error': hasErrors }">
    <label v-if="label" class="Form_label">
      {{ label }}
      <app-tooltip v-if="tooltip" :tooltip="tooltip" />
    </label>
    <span class="input-wrapper" :class="{ 'input-wrapper--suffix': $slots.suffix }">
      <input
        class="Form_control"
        :class="{ 'is-readonly': readonly }"
        :placeholder="placeholder"
        :value="modelValue"
        :type="type"
        :readonly="readonly"
        @input="onInput($event)"
        @blur="onBlur($event)"
      >
      <span v-if="$slots.suffix" class="input_suffix">
        <slot name="suffix"></slot>
      </span>
    </span>
    <p class="text-invalid" v-for="(error, index) in errors" :key="index">
      {{ label }} {{ error }}
    </p>
  </div>
</template>

<script setup>
  import { ref, watch, computed, toRefs } from 'vue';
  import AppTooltip from '@/components/global/AppTooltip';

  defineOptions({
    name: 'AppInputText'
  });

  const props = defineProps({
    modelValue: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    }
  });

  const emit = defineEmits(['update:modelValue', 'blur']);

  const {
    modelValue,
    label,
    tooltip,
    readonly,
    placeholder,
    type,
    errors
  } = toRefs(props);

  const hasErrors = computed(() => {
    return errors.value && errors.value.length > 0;
  });

  const onInput = (event) => {
    emit('update:modelValue', event.target.value);
  };

  const onBlur = (event) => {
    emit('blur', event);
  };
</script>
