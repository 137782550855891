export default function (instance) {
  return {
    getInvoices (pagination) {
      return instance
        .get('/api/invoices', {
          params: {
            ...pagination
          }
        })
        .then(response => response.data)
    },

    getInvoicesFilesDownloadUrls (fileId) {
      return instance
        .get(`/api/invoices/files/downloadUrls/${fileId}`)
        .then(response => response.data)
    },

    getInvoicesPaymentLink (data) {
      return instance
        .post('/api/adyen/create-portal-payment-link', data)
        .then(response => response.data)
    }
  }
}
