<template>
  <div id="app">
    <ModalsContainer />

    <notifications
      position="top center"
      class="app-notification"
      :duration="5000"
      :width="400"
      :ignore-duplicates="true"
    />

    <transition name="fade" mode="out-in">
      <app-loading
        v-if="isLoading"
        class="fullscreen"
        tips="Loading"
      />
      <main class="main" v-else>
        <router-view />
      </main>
    </transition>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { usePrismic } from '@prismicio/vue';
  import { fetchLocalization } from '@/utilities/i18n';
  import { ModalsContainer } from 'vue-final-modal';

  const isLoading = ref(true);
  const store = useStore();
  const { client, filter } = usePrismic();

  onMounted(async () => {
    await Promise.all([
      store.dispatch('locale/fetchLocales', client),
      fetchLocalization(client, filter)
    ])
    store.dispatch('user/setAuthStatus');
    isLoading.value = false;
  });
</script>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main {
      flex: auto;
    }
  }
</style>
