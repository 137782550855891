import _ from 'lodash';
import { prop, ascend, descend, sortWith } from 'ramda';
import { $t } from './i18n/translationHandler';

export const extractErrorMessage = (error) => {
  const status = error?.status;
  const errorMessages = error?.data;

  if (status === 401) {
    return $t(
      'validation.unauthorized',
      'Unauthorized, reloading identify providers'
    )
  }

  if (errorMessages) {
    if (Array.isArray(errorMessages)) {
      const errorArray = _.reduce(
        _.map(errorMessages, 'errorMessages'),
        (flattened, other) => {
          return flattened.concat(other)
        },
        []
      )
      let errorMessageString = ''
      errorArray.forEach((item, index, array) => {
        errorMessageString =
          errorMessageString +
          $t(`validation.${item.code}`, `${item.message}`) +
          `${index === array.length - 1 ? '' : '<br/>'}`
      })
      return errorMessageString
    } else {
      return $t(
        `validation.${errorMessages.code}`,
        `${errorMessages.message}`
      )
    }
  } else {
    return (
      $t(
        'validation.error.message.unknowException',
        'Internal Server Error'
      ) + `${status ? ` (${status})` : ''}`
    )
  }
}

export const sortSubscriptions = subscriptions => {
  const sortMethod = sortWith([
    descend(prop('lelyPackage')),
    descend(prop('subscriptionType')),
    ascend(prop('subscriptionNumber'))
  ])

  return sortMethod(subscriptions)
}

export const clickOutside = {
  beforeMount(el, binding) {
    el.__ClickOutsideHandler__ = event => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.__ClickOutsideHandler__);
  },
  beforeUnmount(el) {
    document.body.removeEventListener('click', el.__ClickOutsideHandler__);
  }
}

export const getBrowserInfo = () => {
  const acceptHeader = 'application/json'
  const screenWidth = window && window.screen ? window.screen.width : ''
  const screenHeight = window && window.screen ? window.screen.height : ''
  const colorDepth = window && window.screen ? window.screen.colorDepth : ''
  const userAgent = window && window.navigator ? window.navigator.userAgent : ''
  const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false

  let language = ''
  if (window && window.navigator) {
      language = window.navigator.language
          ? window.navigator.language
          : window.navigator.browserLanguage // Else is for IE <+ 10
  }

  const d = new Date()
  const timeZoneOffset = d.getTimezoneOffset()
  const browserInformation = {
    acceptHeader,
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    timeZoneOffset,
    language,
    javaEnabled
  }
  return browserInformation
}
