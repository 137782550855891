import i18next from 'i18next'

let instance = null

export default class I18n {
  constructor () {
    if (instance != null) {
      this.i18next = i18next
      return
    }

    i18next.init({
      load: 'currentOnly',
      initImmediate: true,
      lng: 'localization',
      ns: ['app'],
      defaultNS: 'app',
      joinArrays: ' ',
      nsSeparator: ':',
      keySeparator: '~'
    })

    this.i18next = i18next
  }

  static getInstance () {
    if (instance == null) {
      instance = new I18n()
    }

    return instance
  }

  translate (key, options) {
    if (typeof options === 'string') {
      options = {
        defaultValue: options
      }
    }

    return this.i18next.t(key, {
      ...options
    })
  }
}
