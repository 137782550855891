<template>
  <app-icon :name="name" />
</template>

<script setup>
import { computed } from 'vue';
import PackageTypes from '@/enums/PackageTypes';

defineOptions({
  name: 'AppPackageIcon'
});

const props = defineProps({
  packageName: {
    type: String,
    required: true
  }
});

const name = computed(() => {
  const packages = {
    [PackageTypes.HORIZON_ADVANCE]: 'advance',
    [PackageTypes.HORIZON_CONTROL]: 'control',
    [PackageTypes.HORIZON_BASIC]: 'basic'
  }
  return packages[props.packageName];
});
</script>
