import Loading from 'views/Loading.vue';
import { beforeRouteEnterGuard } from '@/utilities/guards/beforeRouteEnterGuard';

export const routes = [
    {
      path: '/',
      name: 'loading',
      component: Loading,
      meta: {
        requiresAuth: false
      },
      beforeEnter: beforeRouteEnterGuard
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import(/* webpackChunkName: "checkout" */ 'views/Checkout'),
      redirect: { name: 'checkout-personal-information' },
      children: [
        {
          path: 'identification',
          name: 'checkout-identification',
          redirect: '/'
        },
        {
          path: 'personal-information',
          name: 'checkout-personal-information',
          component: () => import(/* webpackChunkName: "checkout-personal-information" */ 'views/checkout/PersonalInformation')
        },
        {
          path: 'subscription',
          name: 'checkout-subscription',
          component: () => import(/* webpackChunkName: "checkout-subscription" */ 'views/checkout/Subscription')
        },
        {
          path: 'payment-details',
          name: 'checkout-payment-details',
          component: () => import(/* webpackChunkName: "checkout-payment-details" */ 'views/checkout/PaymentDetails')
        },
        {
          path: 'confirmation',
          name: 'checkout-confirmation',
          component: () => import(/* webpackChunkName: "checkout-confirmation" */ 'views/checkout/Confirmation')
        }
      ]
    },
    {
      path: '/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "account" */ 'views/Account'),
      redirect: { name: 'account-overview' },
      children: [
        {
          path: 'overview',
          name: 'account-overview',
          component: () => import(/* webpackChunkName: "account-overview" */ 'views/account/Overview'),
          meta: {
            title: 'account.page.title.overview',
            defaultTitle: 'Account overview'
          }
        },
        {
          path: 'subscriptions',
          name: 'account-subscriptions',
          component: () => import(/* webpackChunkName: "account-subscriptions" */ 'views/account/Subscriptions'),
          meta: {
            title: 'account.page.title.subscriptions',
            defaultTitle: 'Subscriptions'
          }
        },
        {
          path: 'subscriptions/edit',
          name: 'account-edit-subscription',
          component: () => import(/* webpackChunkName: "account-subscriptions" */ 'views/account/SubscriptionEdit'),
          meta: {
            title: 'account.page.title.editSubscription',
            defaultTitle: 'Edit Subscription'
          }
        },
        {
          path: 'edit-profile',
          name: 'account-edit-profile',
          component: () => import(/* webpackChunkName: "account-edit-profile" */ 'views/account/EditProfile'),
          meta: {
            title: 'account.page.title.editProfile',
            defaultTitle: 'Profile'
          }
        },
        {
          path: 'credit',
          name: 'account-credit',
          component: () => import(/* webpackChunkName: "account-edit-profile" */ 'views/account/Credit'),
          meta: {
            title: 'account.page.title.credit',
            defaultTitle: 'Credits'
          }
        },
        {
          path: 'invoices',
          name: 'account-invoices',
          component: () => import(/* webpackChunkName: "account-invoices" */ 'views/account/Invoices'),
          meta: {
            title: 'account.page.title.invoices',
            defaultTitle: 'Invoices'
          }
        },
        {
          path: 'help',
          name: 'account-help',
          component: () => import(/* webpackChunkName: "account-help" */ 'views/account/Help'),
          meta: {
            title: 'account.page.title.help',
            defaultTitle: 'Help'
          }
        },
        {
          path: 'digital-services',
          name: 'account-digital-services',
          component: () => import('views/account/DigitalServices'),
          meta: {
            title: 'account.page.title.digitalServices',
            defaultTitle: 'Digital Services'
          }
        }
      ]
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
];

