<template>
  <div class="AppRadio">
    <div class="AppRadio_control" :class="{ checked }">
      <input
        type="radio"
        :id="id"
        class="input-hide"
        v-bind="$attrs"
        @change="handleChange"
        :checked="checked"
      >
      <label :for="id"></label>
    </div>
    <label
      v-if="label"
      class="AppRadio_label"
      :for="id"
      v-html="label"
    >
    </label>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, useAttrs } from 'vue';

defineOptions({
  name: 'AppRadio',
  inheritAttrs: false,
});

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  id: {
    type: String,
    default: null
  }
});

const emits = defineEmits(['update:modelValue']);
const attrs = useAttrs();

const checked = computed(() => props.modelValue === attrs.value);

const handleChange = () => {
  emits('update:modelValue', attrs.value);
};
</script>

<style lang="scss">
  .AppRadio {
    display: flex;
    align-items: center;

    &_label {
      cursor: pointer;
      font-size: .14rem;
      line-height: .16rem;
      color: $body-color;
    }

    &_control {
      margin-right: .1rem;
      display: inline-flex;
      flex-shrink: 0;

      label {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: .2rem;
        width: .2rem;
        border: .01rem solid $border-color;
        border-radius: 1rem;
        box-shadow: inset 0 .01rem .05rem rgba(51, 51, 51, .1);
        background: $white;

        &::after {
          content: "";
          width: .1rem;
          height: .1rem;
          border: .02rem solid $red-dark;
          border-radius: 1rem;
          background: $red-dark;
          opacity: 0;
          margin-top: -.02rem;
          transition: ease opacity .2s;
          position: relative;
          top: 5%;
        }
      }

      input[type=Radio]:checked + label {
        //border: .01rem solid $red-dark;
        border-radius: 1rem;

        &::after {
          opacity: 1;
        }
      }
    }
  }
</style>
