import { trimStart, first } from 'lodash';
import { api } from '@/api/backend/index';
import { showNotification } from '@/utilities/notifications/notificationHandler';
import { routes, locales } from '@/config/constants/locales';

const getDefaultLang = () => {
  const browserLanguage = navigator.language || navigator.userLanguage
  let lang = null
  if (browserLanguage) {
    if (browserLanguage.length === 2) {
      if (routes[browserLanguage]) {
        lang = routes[browserLanguage]
      } else {
        lang = `${browserLanguage}-${browserLanguage}`
      }
    } else {
      lang = browserLanguage.toLowerCase()
    }
  }
  return locales.includes(lang) ? lang : 'en-us'
}
const detectLocaleCode = (() => {
  const pathName = trimStart(window.location.pathname, '/')
  if (pathName) {
    const paths = pathName.split('/')
    const firstPath = first(paths)
    if (routes[firstPath]) {
      window.location.href = window.location.href.replace(firstPath, routes[firstPath])
    } else if (/^[a-zA-Z]{2}-[a-zA-Z]{2}$/.test(firstPath)) {
      return firstPath
    } else if (firstPath === 'registration') {
      window.location.href = `${window.location.origin
        }/${getDefaultLang()}/registration`
    } else {
      window.location.href = `${window.location.origin
      }/${getDefaultLang()}/${pathName}`
    }
  } else {
    const savedLocale = localStorage.getItem('userLocale')
    if (savedLocale) {
      return savedLocale
    }
    return getDefaultLang()
  }
})()

const state = () => ({
  locales: [],
  localeCode: detectLocaleCode,
  paymentMethodsConfig: [],
  vatMessage: [],
  uaLinks: []
})

const getters = {
  currentLocale: state =>
    state.locales.length &&
    state.locales.find(item => item.code === state.localeCode),
  vatByCode: state =>
    state.vatMessage.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value
      }),
      {}
    ),
  pdfLinks: state =>
    state.uaLinks.reduce(
      (acc, item) => ({
        ...acc,
        [item.locale]: {
          europe: item.europe.url,
          north_america: item.north_america.url,
          canada: item.canada.url
        }
      }),
      {}
    )
}

const mutations = {
  setLocales (state, locales) {
    state.locales = locales
  },

  setPaymentMethodsConfig (state, paymentMethodsConfig) {
    state.paymentMethodsConfig = paymentMethodsConfig
  },

  setVatMessage (state, message) {
    state.vatMessage = message
  },

  setUALinks (state, links) {
    state.uaLinks = links
  }
}

const actions = {
  async fetchLocales ({ commit, getters, state }, client) {
    try {
      const response = await client.getSingle('locales')
      if (response?.data?.list) {
        commit('setLocales', response.data.list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (getters.currentLocale) {
        localStorage.setItem('userLocale', state.localeCode);
      } else {
        window.location.href = window.location.origin;
      }
    }
  },

  async fetchPaymentMethodsConfig ({ commit }) {
    try {
      const paymentMethods = await api.payment.getMethods();
      commit('setPaymentMethodsConfig', paymentMethods);
    } catch (error) {
      showNotification({
        type: 'error',
        title: error
      });
    }
  },

  async fetchUALinks ({ commit }, client ) {
    try {
      const links = await client.getSingle('user_agreement');
      commit('setUALinks', links.data.links);
    } catch (error) {
      showNotification({
        type: 'error',
        title: error
      });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
