import { extractErrorMessage } from 'utilities/index';
import { cloneDeep, omit } from 'lodash';
import { mapCountryCodeA3 } from '@/utilities/culture/country';
import accountApis from '@/api/backend/resources/account';
import paymentApis from '@/api/backend/resources/payment';
import { instance } from '@/api/backend/index';
import { showNotification } from '@/utilities/notifications/notificationHandler';

const state = () => ({
  personalInfo: null,
  cachedPersonalInfo: null,
  isPersonalInfoLoading: true,
  isPersonalInfoFormInvalid: false,

  paymentDetails: null,
  isPaymentDetailsLoading: true,

  isEditable: false,
  isSaving: false
})

const getters = {
  countryCode: state => state.personalInfo && state.personalInfo.billingAddress.countryCode,

  countryCodeA3: (state, getters) => getters.countryCode && mapCountryCodeA3(getters.countryCode),

  normalizedCachedPersonalInfo: state => omit(
    state.cachedPersonalInfo,
    [
      'accountNumber',
      'email',
      'onboardingReady',
      'companyName',
      'customerNumber',
      'billingAddress.addressLine1',
      'billingAddress.addressLine2',
      'billingAddress.addressLine3',
      'billingAddress.addressLine4',
      'billingAddress.city',
      'billingAddress.postalCode',
      'billingAddress.stateOrProvince',
      'billingAddress.countryCode'
    ]
  )
}

const mutations = {
  setPersonalInfo (state, data) {
    state.personalInfo = data
  },
  setCachedPersonalInfo (state, data) {
    state.cachedPersonalInfo = data
  },
  setIsPersonalInfoLoading (state, status) {
    state.isPersonalInfoLoading = status
  },
  setIsPersonalInfoFormInvalid (state, status) {
    state.isPersonalInfoFormInvalid = status
  },

  setPaymentDetails (state, data) {
    state.paymentDetails = data
  },
  setIsPaymentDetailsLoading (state, status) {
    state.isPaymentDetailsLoading = status
  },

  setIsEditable (state, status) {
    state.isEditable = status
  },
  setIsSaving (state, status) {
    state.isSaving = status
  }
}

const accountRequests = accountApis(instance);
const paymentRequests = paymentApis(instance);

const actions = {
  async fetchPersonalInfo ({ commit }) {
    try {
      const data = await accountRequests.getInformation();
      commit('setPersonalInfo', data)
      commit('setCachedPersonalInfo', cloneDeep(data))
      commit('setIsPersonalInfoLoading', false)
      window.mopinionCountryCode = data.billingAddress.countryCode
      window.mopinionUserEmail = data.email
      return data;
    } catch (error) {
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    }
  },

  async fetchPaymentDetails ({ commit }) {
    await paymentRequests.getDefault().then(data => {
      if (data) {
        commit('setPaymentDetails', data);
        commit('setIsPaymentDetailsLoading', false);
      }
    }).catch(error => {
      commit('setIsPaymentDetailsLoading', false);
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    })
  },

  onCancel ({ state, commit }) {
    commit('setCachedPersonalInfo', cloneDeep(state.personalInfo));
    commit('setIsEditable', false);
    commit('setIsSaving', false);
  },

  onSave ({ state, getters, commit }) {
    commit('setIsSaving', true);
      accountRequests.updateInformation(getters.normalizedCachedPersonalInfo).then(data => {
      commit('setPersonalInfo', cloneDeep(state.cachedPersonalInfo));
      commit('setIsSaving', false);
      commit('setIsEditable', false);
    }).catch(error => {
      commit('setIsSaving', false);
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
