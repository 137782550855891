import MockAdapter from 'axios-mock-adapter'
import { cloneDeep } from 'lodash'

function fixture (name) {
  return cloneDeep(require(`./fixtures/${name}.json`))
}

export function mock (instance, options) {
  const _options = Object.assign({}, {
    ...options,
    delayResponse: 500
  }, options)

  const mock = new MockAdapter(instance, _options)

  // fetchProfile
  mock
    .onGet('/api/user/details')
    .reply(200, fixture('user'))

  mock
    .onGet('/api/customer')
    .reply(200, fixture('customer'))
}
