import AuthService from '@/utilities/msal';

const beforeRouteEnterGuard = (to, from, next) => {
  const authService = new AuthService();
  if ((to.redirectedFrom === '/registration' || to.redirectedFrom === '/registration/') && !localStorage.getItem('isAuthenticated')) {
    authService.signUp();
  } else {
    next();
  }
};

export { beforeRouteEnterGuard };