<template>
  <table class="Table">
    <tbody>
      <div v-for="record in resolvedData" :key="record.id">
        <tr class="table_row mobile">
          <td class="inner_table_wrap">
            <table width="100%">
              <tr
                v-for="column in columns"
                :key="column.key"
                :class="[`${column.key}-row`]"
              >
                <slot
                  :name="`${column.key}-row`"
                  :record="record"
                  :column="column"
                >
                  <th>{{ $t(column.title.key, column.title.placeholder) }}</th>
                  <td>{{ record[column.key] }}</td>
                </slot>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="table_divider" :key="`divider_${record.id}`">
          <td height="1"></td>
        </tr>
      </div>
    </tbody>
  </table>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { prop, ascend, descend, sort } from 'ramda';

  defineOptions({
    name: 'AppMobileTable'
  });

  const props = defineProps({
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        showDivider: false,
        defaultSort: 'descending',
      }),
    },
  });

  const sortingId = ref('');
  const sortingOrder = ref('');

  const resolvedData = computed(() => {
    if (sortingOrder.value === 'ascending') {
      return sort(ascend(prop(sortingId.value)), props.data);
    }
    if (sortingOrder.value === 'descending') {
      return sort(descend(prop(sortingId.value)), props.data);
    }
    return props.data;
  });
</script>

<style lang="scss">
  .Table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    font-size: .13rem;
    line-height: .2rem;

    @include media (desktop) {
      font-size: .14rem;
    }

    .table_row {
      th {
        text-transform: capitalize;
      }

      &.mobile {
        td.inner_table_wrap {
          table {
            border-collapse: separate;
            border-spacing: 0;
          }

          th,
          td {
            padding: .05rem;
            width: 50%;
          }
        }
      }

      &.desktop {
        th,
        td {
          padding: .1rem;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        td {
          color: $body-color;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .table_divider {
      td {
        width: 100%;
        background: $grey-300;
        padding: 0;
      }

      &:last-child {
        td {
          display: none;
          width: 0;
          height: 0;
        }
      }
    }
  }
</style>
