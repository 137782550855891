import '@/sass/app.scss';
import '@/utilities/datadog'
import 'vue-final-modal/style.css';
import 'floating-vue/dist/style.css'
import { createApp } from 'vue';
import App from './App.vue';
import { globalComponentsRegister } from './components/global';
import store from '@/store';
import Api from './api/backend';
import prismic from './api/prismic';
import i18n from 'utilities/i18n';
import adyen from 'utilities/adyen';
import Notifications from '@kyvg/vue3-notification';
import AuthService from 'utilities/msal';
import * as filters from 'utilities/filters';
import VueScreen from 'vue-screen';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { VTooltip } from 'floating-vue';
import trackEvent from 'utilities/event-tracker';
import VueScrollTo from 'vue-scrollto';
import { createVfm } from 'vue-final-modal';
import { setNotify } from '@/utilities/notifications/notificationHandler';
import { setTranslation } from '@/utilities/i18n/translationHandler';
import { createAppRouter } from './router';
import { clickOutside } from '@/utilities';
import { eventBus } from './utilities/eventBus';

const app = createApp(App);
const metaManager = createMetaManager();
const vfm = createVfm();

// Register global components, plugins, directives
globalComponentsRegister(app);
app.use(Api, { mock: false });
app.use(i18n);
setTranslation(app.config.globalProperties.$t);
app.use(adyen);
app.use(Notifications);
setNotify(app.config.globalProperties.$notify);
app.use(metaManager);
app.use(metaPlugin);
app.use(VueScreen, {
  grid: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1025,
    xl: 1200
  }
});
app.use(trackEvent);
app.use(VueScrollTo);
app.use(prismic);
app.use(vfm);

// Using provide for making AuthService available in the app
app.provide('$auth', new AuthService());
app.provide('eventBus', eventBus);

// Register the clickOutside directive globally
app.directive('click-outside', clickOutside);
app.directive('tooltip', VTooltip);

// register components
app.component(VTooltip);

// Register global filters (now called global methods)
for (const [key, filterFunc] of Object.entries(filters)) {
  app.config.globalProperties[key] = filterFunc;
}

app.use(store);
app.use(createAppRouter(store));
app.mount('#app');
