<template>
  <a
    class="AppHamburgerBtn"
    :class="{ 'AppHamburgerBtn-open': isOpen }"
    href="javascript:;"
    @click="toggleMenu"
  >
    <span
      v-for="index in 4"
      :key="index"
      class="AppHamburgerBtn_line"
    >
    </span>
  </a>
</template>

<script setup>
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false
    }
  });

  const emit = defineEmits(['toggle']);

  const toggleMenu = () => {
    emit('toggle', !props.isOpen);
  };
</script>

<style lang="scss">
  // Tasty CSS-animated hamburgers Doc: https://jonsuh.com/hamburgers/
  .AppHamburgerBtn {
    $width: .18rem;
    $height: .12rem;
    $line-height: .02rem;

    display: block;
    position: relative;
    outline: none;
    width: $width;
    height: $height;

    &_line {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      background-color: $grey-500;
      opacity: 1;
      height: $line-height;
      transform: rotate(0deg);
      transition: transform .35s cubic-bezier(.22, .44, 0, 1), opacity .25s;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 50%;
        // margin-top: -1 * math.div($line-height / 2);
        margin-top: - ($line-height / 2);
      }

      &:nth-child(4) {
        bottom: 0;
      }
    }

    &-open {
      .AppHamburgerBtn_line {
        &:nth-child(1) {
          opacity: 0;
          transform: translateY(50%) scale(.0001);
        }

        &:nth-child(2) {
          transform: rotate(45deg) scale(.8);
        }

        &:nth-child(3) {
          transform: rotate(-45deg) scale(.8);
        }

        &:nth-child(4) {
          opacity: 0;
          transform: translateY(-50%) scale(.0001);
        }
      }
    }
  }
</style>
