// doc: https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-v2-javascript
// doc: https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications

import store from 'store'
import queryString from 'query-string'

const extraQueryParameters = {
  ui_locales: store?.state?.locale?.localeCode,
  user_environment: process.env.VUE_APP_TRACK_ENV
}

export default class AuthService {
  login (redirectUrl) {
    if (redirectUrl) {
      window.location.replace(`/auth/login?returnUrl=${redirectUrl}`)
    } else {
      const queryParameters = queryString.stringify(extraQueryParameters)
      window.location.replace(`/auth/login?${queryParameters}`)
    }
  }

  signUp () {
    const queryParameters = queryString.stringify(extraQueryParameters)
     window.location.replace(`/auth/signup?${queryParameters}`)
  }

  acquireToken () {
    const queryParameters = queryString.stringify(extraQueryParameters)
    window.location.replace(`/auth/login?${queryParameters}`)
  }

  logout () {
    const queryParameters = queryString.stringify(extraQueryParameters)
    window.location.replace(`/auth/logout?${queryParameters}`)
  }

  resetPassword (emailAddress) {
    const queryParameters = queryString.stringify({ ...extraQueryParameters, email_address: emailAddress })
    window.location.replace(`/auth/reset-password?${queryParameters}`)
  }

  changeSignInName () {
    const queryParameters = queryString.stringify(extraQueryParameters)
    window.location.replace(`/auth/update-email?${queryParameters}`)
  }
}
