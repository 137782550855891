import { format } from 'date-fns'
import accounting from 'accounting'
import store from 'store'
import moment from 'moment'

export const formatLocalDate = date => {
  const dateFormat =
    store.getters['locale/currentLocale'].date_format || 'MM/dd/yyyy'
  return date && format(new Date(date), dateFormat)
}

export const customDateFormat = (date, format = 'D MMMM') => {
  if (format === 'D MMMM') {
    format =
      moment().format('YYYY') !== moment(date).format('YYYY')
        ? 'D MMMM YYYY'
        : 'D MMMM'
  }
  return moment(date).format(format)
}

export const formatLocalDateWithTime = date => {
  const dateFormat =
    (store.getters['locale/currentLocale'].date_format || 'MM/dd/yyyy') +
    ' hh:mm'
  return date && format(new Date(date), dateFormat)
}

const currencyFormats = [
  {
    code: 'EUR',
    options: ['€', 2, '.', ',', '%s %v']
  },
  {
    code: 'USD',
    options: ['$', 2, ',', '.', '%s %v']
  },
  {
    code: 'CAD',
    options: ['$', 2, ',', '.', '%s %v']
  },
  {
    code: 'CHF',
    options: ['Fr', 2, ',', '.', '%v %s']
  },
  {
    code: 'CZK',
    options: ['Kč', 2, ',', '.', '%v %s']
  },
  {
    code: 'DKK',
    options: ['Kr', 2, ',', '.', '%s %v']
  },
  {
    code: 'GBP',
    options: ['£', 2, ',', '.', '%s %v']
  },
  {
    code: 'HUF',
    options: ['Ft', 2, ',', '.', '%v %s']
  },
  {
    code: 'NOK',
    options: ['Kr', 2, ',', '.', '%s %v']
  },
  {
    code: 'PLN',
    options: ['zł', 2, ',', '.', '%v %s']
  },
  {
    code: 'SEK',
    options: ['Kr', 2, ',', '.', '%v %s']
  }
]

export const formatCurrency = (
  amount,
  currencyCode = 'EUR',
  decimal = null,
  format = null,
  thousands = null
) => {
  const currencyObj = currencyFormats.find(item => item.code === currencyCode);
  
  if (!currencyObj) {
    console.warn(`Currency code ${currencyCode} not found.`);
    return amount;
  }
  
  const currencyOptions = currencyObj.options; 

  if (decimal !== null) currencyOptions[1] = decimal;
  if (format) currencyOptions[4] = format;
  if (thousands !== null) currencyOptions[2] = thousands;

  return accounting.formatMoney(
    accounting.toFixed(amount, decimal),
    ...currencyOptions
  );
}

export const colorCurrency = (amount, currencyCode = 'EUR', decimal = 2) => {
  const currency = currencyFormats.find(item => item.code === currencyCode)
    .options[0]
  let sign = ''
  if (Math.sign(amount) > 0) {
    sign = '+'
  } else if (Math.sign(amount) < 0) {
    sign = '-'
  }
  const options = [currency, decimal, ',', '.', '%s%v']
  const value = Math.abs(amount)
  return `${sign}${accounting.formatMoney(
    accounting.toFixed(value, decimal),
    ...options
  )}`
}

export const encryptField = (field, displayNumber = -4) => {
  if (!field) return
  const displayText = field.slice(displayNumber)
  return `****${displayText}`
}
