export default {
  install: (app) => {
    app.config.globalProperties.$trackEvent = function (variables) {
      const trackData = JSON.parse(localStorage.getItem('trackData'));
      window.dataLayer.push({
        ...variables,
        'loginstatus': 'logged in',
        'userID': trackData?.userID || '',
        'movexnumber': trackData?.movexnumber || '',
        'company': trackData?.company || '',
        'lelycenter': trackData?.lelycenter || '',
        'lelycentercode': trackData?.lelycentercode || '',
        'user_environment': process.env.VUE_APP_TRACK_ENV
      });
    };
  }
};
