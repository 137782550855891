<template>
  <div class="AppSelect" :class="{ 'error': errors.length > 0 }">
    <label v-if="label" :class="labelClass">
      {{ label }}
    </label>
    <multiselect
      v-bind="$attrs"
      :modelValue="value"
      :placeholder="placeholder"
      :options="options"
      :searchable="searchable"
      :allowEmpty="false"
      :showLabels="false"
      :track-by="$attrs['track-by']"
      :label="$attrs['track-label']"
      :openDirection="openDirection"
      @update:modelValue="updateValue"
    >
      <template v-if="searchable" #noResult>
        <b>{{ $t('common.tips.select.search.noResult', 'No result') }}</b>
      </template>
    </multiselect>

    <p
      class="text-invalid"
      v-for="(error, index) in errors"
      :key="index"
    >
      {{ label }} {{ error }}
    </p>
  </div>
</template>

<script>
  import multiselect from 'vue-multiselect';

  export default {
    name: 'AppSelect',

    components: {
      multiselect
    },

    model: {
      prop: 'modelValue',
      event: 'update:modelValue'
    },

    props: {
      label: {
        type: String,
        default: null
      },

      labelClass: {
        type: String,
        default: ''
      },

      placeholder: {
        type: String,
        default: ''
      },

      searchable: {
        type: Boolean,
        default: false
      },

      value: {
        type: [Object, Array, String, Number],
        default: null
      },

      options: {
        type: Array,
        required: true
      },

      openDirection: {
        type: String,
        default: 'bottom'
      },

      errors: {
        type: Array,
        default () {
          return []
        }
      }
    },
    setup(props, { emit }) {
      const updateValue = (value) => {
        emit('update:modelValue', value);
      }

      return {
        updateValue
      }
    }
  }
</script>

<style lang="scss">
  @import '~vue-multiselect/dist/vue-multiselect.css';

  .AppSelect {
    .multiselect {
      position: relative;
      cursor: pointer;
      min-height: .4rem;

      &--active {
        cursor: default;

        .multiselect__select {
          transform: rotate(180deg) translateY(50%);
        }

        .multiselect__placeholder {
          display: inline-block;
        }
      }

      &__placeholder {
        margin-bottom: 0;
      }

      &__tags {
        padding: .11rem .35rem .11rem .2rem;
        color: $body-color;
        border: .01rem solid $border-color;
        box-shadow: inset 0 .02rem .05rem rgba(222, 219, 214, .4);
        border-radius: .04rem;
      }

      &__select {
        position: absolute;
        right: .15rem;
        top: 50%;
        transform: translateY(-50%);
        width: .1rem;
        height: .1rem;
        padding: 0;
        transition: transform .3s ease;
        background: no-repeat center url('~assets/images/triangle.svg');

        &::before {
          display: none;
        }
      }

      &__single,
      &__input,
      &__placeholder {
        cursor: pointer;
        min-height: .16rem;
        padding: 0;
        margin-bottom: 0;
        font-size: .14rem;
        line-height: .16rem;
      }

      &__input {
        @include placeholder($placeholder);
      }

      &__content-wrapper {
        max-height: 200px !important;
        border: .01rem solid $border-color;
        border-top: none;
        border-bottom-left-radius: .04rem;
        border-bottom-right-radius: .04rem;
        box-shadow: inset 0 .02rem .05rem rgba(222, 219, 214, .4);
      }

      &__option {
        padding: .11rem .2rem;
        font-size: .14rem;
        line-height: .16rem;

        &--highlight {
          background-color: $grey-200;
          color: $body-color;
        }

        &--selected {
          cursor: default;
          font-weight: 500;
          background-color: $red-dark;
          color: $white;

          &.multiselect__option--highlight {
            background: $red-dark;
            color: $white;
          }
        }
      }
    }

    &.error {
      .multiselect__tags {
        border-color: $error-color !important;
      }
    }

    .text-invalid {
      margin-top: .05rem;
      color: $error-color;
      font-size: .12rem;
    }
  }
</style>
