export default function (instance) {
  return {
    activation () {
      return instance
        .put('/api/fulfillment/activation')
        .then(response => response.data)
    },

    activationNew (productId) {
      return instance
        .put(`/api/fulfillment/activation/${productId}`)
        .then(response => response.data)
    },

    updateBillingStatus (licenseKey, body) {
      return instance
        .put(`/api/fulfillment/billing-registered/${licenseKey}`, body)
        .then(response => response.data)
    }
  }
}
