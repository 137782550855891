import { datadogRum } from '@datadog/browser-rum'
const env = process.env.NODE_ENV
if (env === 'production') {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID ?? '',
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN ?? '',
    site: 'datadoghq.eu',
    service: 'horizon-billingportal',
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogRum.startSessionReplayRecording()
}
