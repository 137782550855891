export default function (instance) {
  return {
    getDefault () {
      return instance
        .get('/api/payment/default')
        .then(response => response.data)
    },

    getMethods () {
      return instance
        .get('/api/payment/methods')
        .then(response => response.data)
    },

    save (data) {
      return instance
        .post('/api/payment/save-method', data, {
          timeout: 120000
        })
        .then(response => response.data)
    },

    getManualPaymentCharge () {
      return instance
        .get('/api/payment/wire-transfer/charge')
        .then(response => response.data)
    },

    handle3DSRedirectResult (redirectResult) {
      return instance
        .post('/api/payment/result', {
          redirectResult
        })
        .then(response => response.data)
    }
  }
}
