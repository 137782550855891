// External server lang mapping
export const routes = {
    cs: 'cs-cz',
    da: 'da-dk',
    de: 'de-de',
    en: 'en-us',
    tr: 'en-us',
    ja: 'en-us',
    ko: 'en-us',
    he: 'en-us',
    ca: 'en-us',
    zh: 'en-us',
    hr: 'en-us',
    el: 'en-us',
    'en-GB': 'en-us',
    fa: 'en-us',
    ro: 'en-us',
    ru: 'en-us',
    th: 'en-us',
    sl: 'en-us',
    be: 'en-us',
    uk: 'en-us',
    'zh-Hant': 'en-us',
    es: 'es-es',
    et: 'et-ee',
    fi: 'fi-fi',
    fr: 'fr-fr',
    hu: 'hu-hu',
    is: 'is-is',
    it: 'it-it',
    lt: 'lt-lt',
    lv: 'lv-lv',
    no: 'nb-no',
    nl: 'nl-nl',
    pl: 'pl-pl',
    pt: 'pt-pt',
    sk: 'sk-sk',
    sv: 'sv-se'
  };
  
  // languages supported by lely (TODO: fetch from prismic to always have the supported langauges)
  export const locales = [
    'en-us',
    'fr-fr',
    'de-de',
    'nl-nl',
    'cs-cz',
    'da-dk',
    'es-es',
    'et-ee',
    'fi-fi',
    'hu-hu',
    'it-it',
    'is-is',
    'lt-lt',
    'lv-lv',
    'nb-no',
    'pl-pl',
    'pt-pt',
    'sk-sk',
    'sv-se'
  ];
