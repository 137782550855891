import I18n from './i18n';
import { omit } from 'lodash';
import store from 'store';
import { useGlobalProperties } from '@/utilities/helpers/vueInstanceHelper';
import { adyenLocale } from '@/config/constants/adyenLocale';

const i18n = I18n.getInstance();

const normalizeLocale = locale => {
  return locale
    .split('-')
    .map((item, index) => {
      if (index === 1) {
        return item.toUpperCase();
      }
      return item;
    })
    .join('-');
}

const isSupported = locale => {
  return adyenLocale.find(item => item === locale);
}

export default {
  install(app) {
    app.config.globalProperties.$t = function(key, options) {
      return i18n.translate(key, options);
    }
    app.config.globalProperties.$i18next = i18n.i18next;
  }
}

export async function fetchLocalization(client, filter) {
  const localeCode = store.state.locale.localeCode;
  const globalProperties = useGlobalProperties();
  let $adyenConfig = globalProperties.$adyenConfig;
  
  try {
    const {
      results: [{ data }]
    } = await client.get({
      filters: filter.at('document.type', 'localization'),
      lang: localeCode
    });

    const localizationData = Object.entries(
      omit(data, ['title', 'vat'])
    ).reduce(
      (acc, [group, array]) => ({
        ...acc,
        ...array.reduce(
          (acc, { key, value }) => ({
            ...acc,
            [`${group}.${key}`]: value
          }),
          {}
        )
      }),
      {}
    );

    if (data.vat) {
      store.commit('locale/setVatMessage', data.vat);
    }

    i18n.i18next.addResourceBundle(
      'localization',
      'app',
      localizationData,
      true,
      true
    );
    
    if (data.payment && data.payment.length > 0) {
      const paymentData = data.payment.reduce(
        (acc, { key, value }) => ({
          ...acc,
          [`${key}`]: value
        }),
        {}
      );

      const getLocale = locale => {
        if (isSupported(locale) || paymentData) {
          return locale;
        }
        return 'en-US';
      }

      if ($adyenConfig) {
        const locale = normalizeLocale(localeCode);

        $adyenConfig = {
          ...$adyenConfig,
          locale: getLocale(locale),
          translations: {
            [locale]: { ...paymentData }
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}
