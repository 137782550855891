import { getCurrentInstance, ref } from 'vue';

export function useVueInstance() {
    const instance = getCurrentInstance();

    if (!instance) {
        throw new Error('This function can only be used within setup() or other composable functions.');
    }

    return instance;
}

export function useAppContext() {
    const instance = useVueInstance();
    return instance.appContext;
}

export function useGlobalProperties() {
    const instance = useVueInstance();
    return instance.appContext.config.globalProperties;
}

const storeInstance = ref(null);
export function setStoreInstance(store) {
    storeInstance.value = store;
}

export function getStoreInstance() {
    return storeInstance.value;
}

const routerInstance = ref(null);
export function setRouterInstance(router) {
    routerInstance.value = router;
}

export function getRouterInstance() {
    return routerInstance.value
}