<template>
  <svg
    class="icon"
    :class="[iconClass, $attrs.direction, $attrs.class]"
  >
    <use :href="href" />
  </svg>
</template>

<script setup>
  // import all svg icon
  const svgContext = require.context('@/assets/icons', true, /\.svg$/);
  svgContext.keys().forEach(svgContext);

  import { computed, useAttrs } from 'vue';

  defineOptions({
    name: 'AppIcon',
    inheritAttrs: false,
  })

  const props = defineProps({
    name: {
      type: String,
      required: true
    }
  });
  
  const attrs = useAttrs();

  const iconClass = computed(() => `icon-${props.name}`);
  const href = computed(() => `#${props.name}`);
</script>

<style lang="scss">
  .icon {
    display: inline-block;
    vertical-align: middle;
    width: .12rem;
    height: .12rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    &.left {
      transform: rotate(180deg);
    }

    &.icon-arrow {
      width: .05rem;
      height: .1rem;
    }

    &.icon-info_outline {
      width: .18rem;
      height: .18rem;
    }

    &.icon-tick {
      width: .15rem;
      height: .12rem;
    }

    &.icon-cross {
      width: .15rem;
    }
  }
</style>
