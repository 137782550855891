<template>
  <div
    class="AppSteps"
    :class="`AppSteps--${direction}`"
  >
      <app-step v-for="(item, index) in navSteps" :key="index"
        :title="item.title"
        :show-description="!isMobile"
        :direction="isDesktop ? Direction.Vertical : Direction.Horizontal"
        :itemIndex="index"
        :steps="navSteps"
        :activeItemIndex="navSteps.findIndex(nav => nav.routeName === route.name)"
        @click="handleStepClick(item.routeName)"
        :is-clickable="false"
      />
  </div>
</template>
  
<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useGrid } from 'vue-screen';
import AppStep from './AppStep.vue';
import { Direction } from '@/config/constants/checkoutConstants';
import { useGlobalProperties } from '@/utilities/helpers/vueInstanceHelper';

defineOptions({
    name: 'AppSteps'
});
  
const { $t } = useGlobalProperties();
const router = useRouter();
const route = useRoute();
const grid = useGrid(); 
const isMobile = computed(() => !grid.md);
const isDesktop = computed(() => grid.lg);
const direction = computed(()=> isDesktop.value ? Direction.Vertical : Direction.Horizontal);
const navSteps = ref([
  {
    title: $t('checkout.step.identification', 'Identification'),
    routeName: ''
  },
  {
    title: $t('checkout.step.information', 'Information'),
    routeName: 'checkout-personal-information'
  },
  {
    title: $t('checkout.step.product', 'Product'),
    routeName: 'checkout-subscription'
  },
  {
    title: $t('checkout.step.paymentDetails', 'Payment details'),
    routeName: 'checkout-payment-details'
  },
  {
    title: $t('checkout.step.confirmation', 'Confirmation'),
    routeName: 'checkout-confirmation'
  }
]);

const handleStepClick = (routeName) => {
  if (routeName) {
    router.push({ name: routeName });
  }
};
</script>
  
  <style lang="scss">
    .AppSteps {
      display: flex;
  
      &--horizontal {
        align-items: flex-start;
      }
  
      &--vertical {
        flex-direction: column;
      }
    }
  </style>
  