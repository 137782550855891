let notify;

export const setNotify = (fn) => {
  notify = fn;
};

export const showNotification = ({ type, title }) => {
  notify({
    type,
    title
  });
};