<template>
  <label
    class="AppDropdownButton"
    v-click-outside="() => submenuOpen = false"
    @click="submenuOpen = !submenuOpen"
  >
    <div class="dd-button">
      {{ title }}
    </div>

    <div class="dd-menu" :class="{ show: submenuOpen }">
      <slot></slot>
    </div>

  </label>
</template>

<script setup>
  import { ref } from 'vue';
  import { clickOutside } from '@/utilities/index';

  defineOptions({
    name: 'AppDropdownButton'
  });

  const props = defineProps({
    title: {
      type: String,
      default: 'User agreement'
    }
  });

  const submenuOpen = ref(false);
</script>

<style lang="scss">
  .AppDropdownButton {
    /* Dropdown */
    display: inline-block;
    position: relative;
    min-width: 1.2rem;

    a {
      text-decoration: none;
      color: #35495e;
      padding: .1rem;
      font-size: .12rem;
      line-height: .18rem;
      white-space: nowrap;
    }

    a:hover {
      color: #222;
      background-color: #efedea;
    }

    .dd-button {
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;

      &::after {
        content: var(--footer-select-icon, url('~assets/images/triangle_white.svg'));
        margin-left: .15rem;
      }
    }

    .dd-menu {
      display: none;
      position: absolute;
      bottom: 100%;
      border: 1px solid #ccc;
      padding: 0;
      margin: 2px 0 0 0;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, .1);
      background-color: #fff;
      flex-direction: column;
      left: 0;
      right: 5px;
    }

    .dd-menu.show {
      display: flex;
    }
  }
</style>
