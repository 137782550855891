<template>
  <button
    class="AppButton"
    :class="[
      `${theme}`,
      {
        'loading': isLoading,
        'disabled': disabled,
        'block': block
      }
    ]"
    :type="type"
    v-bind="$attrs"
    :disabled="isLoading || disabled"
  >
    <app-spinner v-if="isLoading" :color="spinnerColor" />
    <span class="AppButton_content">
      <slot></slot>
    </span>
  </button>
</template>

<script setup>
  import { computed } from 'vue';
  import AppSpinner from '@/components/global/AppSpinner.vue';

  defineOptions({
    name: 'AppButton',
    inheritAttrs: false
  })

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button',
      validator: val => ['button', 'submit', 'reset'].includes(val)
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    spinnerColor: {
      type: String,
      default: '#fff'
    },
    theme: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'default', 'link', 'icon'].includes(val)
    },
    block: {
      type: Boolean,
      default: false
    }
  });

  const theme = computed(() => `AppButton-${props.theme}`);
</script>

<style lang="scss">
  .AppButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: .04rem;
    font-size: .14rem;
    line-height: .16rem;
    font-weight: 400;
    padding: .12rem .15rem;

    &-primary {
      background-color: $primary;
      color: $white;

      &:hover {
        background-color: $red-900;
      }
    }

    &-default {
      padding: .11rem .15rem;
      border: .01rem solid $grey-300;
      background-color: $white;
      box-shadow: 0 .02rem .03rem rgba(118, 110, 104, .1);
      color: $grey-500;

      &:hover {
        background-color: $grey-200;
      }
    }

    &-link {
      padding: 0;
      color: $grey-500;
      text-decoration: underline;

      &:active,
      &:hover {
        color: $primary;
        text-decoration: none;
      }

      &.disabled {
        color: $grey-500;
        text-decoration: underline;
      }
    }

    &-icon {
      padding: 0;
      height: .4rem;
      width: .4rem;
      border: .01rem solid $grey-300;
      background-color: $white;
      box-shadow: 0 .02rem .03rem rgba(118, 110, 104, .1);
      color: $grey-500;

      &:hover {
        background-color: $grey-200;
      }
    }

    &.block {
      width: 100%;
    }

    &.disabled,
    &.loading,
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    .AppSpinner {
      margin-right: .1rem;
    }

    &_content {
      display: inline-flex;
      align-items: center;
    }
  }
</style>
