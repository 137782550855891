<template>
  <div
    class="AppTableSorter"
    :class="[sortingOrderClass]"
    @click="handleSortClick"
  >
    <slot></slot>
    <div class="sorter_buttons">
      <app-icon class="ascending" name="sorting-arrow" />
      <app-icon class="descending" name="sorting-arrow" />
    </div>
  </div>
</template>

<script setup>
  import { ref, watch, computed, onMounted } from 'vue';

  defineOptions({
    name:'AppTableSorter'
  });

  const props = defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    defaultOrder: {
      type: String,
      default: 'default',
      validator: val => ['default', 'descending', 'ascending'].includes(val)
    }
  });


  const emit = defineEmits(['update:modelValue', 'sorting-order']);

  const sortingOrder = ref('default');
  const orderQueue = ['default', 'descending', 'ascending'];

  const isCurrentSorting = computed(() => props.modelValue !== '' && props.id !== '' && props.modelValue === props.id);

  const sortingOrderClass = computed(() => {
    switch (sortingOrder.value) {
      case 'default': return 'is-default';
      case 'ascending': return 'is-ascending';
      case 'descending': return 'is-descending';
      default: return 'is-default';
    }
  });

  const handleSortClick = () => {
    if (props.id !== '') {
      emit('update:modelValue', props.id);
    }

    const index = orderQueue.indexOf(sortingOrder.value);
    const nextIndex = index === (orderQueue.length - 1) ? 0 : index + 1;
    const orderType = orderQueue[nextIndex];
    sortingOrder.value = orderType;
    emit('sorting-order', orderType);
  };

  watch(isCurrentSorting, (newVal) => {
    if (!newVal) {
      sortingOrder.value = 'default';
    }
  });

  onMounted(() => {
    if (isCurrentSorting.value) {
      sortingOrder.value = props.defaultOrder;
      emit('sorting-order', props.defaultOrder);
    }
  });
</script>

<style lang="scss">
  .AppTableSorter {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &.is-ascending {
      .icon.ascending {
        opacity: 1;
        color: $primary;
      }
    }

    &.is-descending {
      .icon.descending {
        opacity: 1;
        color: $primary;
      }
    }

    .sorter_buttons {
      margin-left: .12rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $grey-400;

      .icon {
        width: .07rem;
        height: .06rem;
        opacity: .6;

        &.descending {
          margin-top: .02rem;
          transform: rotate(-180deg);
        }
      }
    }
  }
</style>
