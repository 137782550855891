import { showNotification } from '@/utilities/notifications/notificationHandler';
import { extractErrorMessage } from '@/utilities/index';
import { instance } from '@/api/backend/index';
import invoicesApis from '@/api/backend/resources/invoices';

const state = () => ({
  invoicesList: null,
  isInvoicesListLoading: false,
  pagination: {
    page: undefined,
    pageSize: undefined,
    totalCount: undefined
  }
})

const mutations = {
  setInvoicesList (state, data) {
    state.invoicesList = data;
  },
  setIsInvoicesListLoading (state, status) {
    state.isInvoicesListLoading = status;
  },
  setInvoicesPagination (state, data) {
    state.pagination = data;
  }
}

const invoicesRequests = invoicesApis(instance);

const actions = {
  fetchInvoicesList ({ commit }, pagination) {
    commit('setIsInvoicesListLoading', true);
    invoicesRequests.getInvoices(pagination).then(response => {
      if (response?.data && response?.pagination) {
        commit('setInvoicesList', response.data);
        commit('setInvoicesPagination', response.pagination);
        commit('setIsInvoicesListLoading', false);
      }
    }).catch(error => {
      commit('setIsInvoicesListLoading', false);
      showNotification({
        type: 'error',
        title: extractErrorMessage(error)
      });
    })
  },
  resetInvoicesList ({ commit }) {
    commit('setInvoicesList', null);
    commit('setInvoicesPagination', {page: undefined, pageSize: undefined, totalCount: undefined});
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
