<template>
  <div class="AppCheckbox">
    <div class="AppCheckbox_control" :class="{ checked: checked === true }">
      <input
        type="checkbox"
        :id="id"
        class="input-hide"
        v-bind="$attrs"
        v-on="listeners"
        :checked="checked"
      >
      <label :for="id"></label>
    </div>
    <label
      v-if="label"
      class="AppCheckbox_label"
      :for="id"
      v-html="label"
    >
    </label>
    <slot></slot>
  </div>
</template>

<script setup>
  import { computed, toRefs, useAttrs } from 'vue';

  defineOptions({
    name: 'AppCheckbox',
    inheritAttrs: false
  });

  const props = defineProps({
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  });

  const { checked } = toRefs(props);

  const attrs = useAttrs();
  const emit = defineEmits(['change']);

  const listeners = computed(() => ({
    ...attrs,
    change: evt => {
      emit('update:modelValue', evt.target.checked);
    }
  }));
</script>

<style lang="scss">
  .AppCheckbox {
    display: flex;
    align-items: center;

    &_label {
      cursor: pointer;
      font-size: .14rem;
      line-height: .16rem;
      color: $body-color;
    }

    &_control {
      margin-right: .1rem;
      display: inline-flex;
      flex-shrink: 0;

      label {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: .2rem;
        width: .2rem;
        border: .01rem solid $border-color;
        border-radius: .02rem;
        box-shadow: inset 0 .01rem .05rem rgba(51, 51, 51, .1);
        background: $white;

        &::after {
          content: "";
          width: .14rem;
          height: .08rem;
          border: .02rem solid $red-dark;
          border-top: none;
          border-right: none;
          background: transparent;
          transform: rotate(-55deg);
          opacity: 0;
          margin-top: -.02rem;
          transition: ease opacity .2s;
        }
      }

      input[type=checkbox]:checked + label {
        border: .01rem solid $red-dark;

        &::after {
          opacity: 1;
        }
      }

      input[type=checkbox]:disabled + label {
        background: $grey-200;
        cursor: not-allowed;
      }
    }
  }
</style>
