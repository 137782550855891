<template>
  <div class="Loading">
    <div class="container">
      <app-loading class="fullscreen" tips="Loading" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { extractErrorMessage } from '@/utilities/index';
import { useGlobalProperties } from '@/utilities/helpers/vueInstanceHelper';

defineOptions({
  name: 'Loading'
});

const isAuthenticated = ref(false);
const $auth = inject('$auth');
const store = useStore();
const route = useRoute();
const router = useRouter();
const { $notify, $api } = useGlobalProperties();

const authenticatedRedirect = async () => {
  if (isAuthenticated.value) {
    const redirect = route.query.redirect;
    if (redirect) {
      router.replace({ path: redirect });
    } else {
      try {
        const { 
          onboardingReady, 
          accountNumber, 
          customerNumber, 
          companyName, 
          resellerAccountNumber 
        } = await $api.account.getInformation();

        if (!localStorage.getItem('trackData')) {
          let resellerName;
          let resellerCode;

          if (resellerAccountNumber) {
            const resellerInfo = await store.dispatch('reseller/fetchResellerInfo', resellerAccountNumber);
            resellerName = resellerInfo?.resellerName;
            resellerCode = resellerInfo?.customerNumber;
          }
          const trackData = {
            userID: accountNumber,
            movexnumber: customerNumber,
            company: companyName,
            lelycenter: resellerName || '',
            lelycentercode: resellerCode || ''
          };

          window.dataLayer.push({
            event: 'pageview',
            loginstatus: 'logged in',
            ...trackData,
            user_environment: process.env.VUE_APP_TRACK_ENV
          });
          localStorage.setItem('trackData', JSON.stringify(trackData));
        }

        if (onboardingReady) {
          router.push({ name: 'account' });
        } else {
          router.push({ name: 'checkout' });
        }
      } catch (error) {
        $notify({
          type: 'error',
          title: extractErrorMessage(error)
        });
      }
    }
  }
};

watch(() => store.getters['user/isAuthenticated'], (val) => {
  if (!val) {
    $auth.login(route.query.redirect);
  }
}, { immediate: true });

onMounted(() => {
  isAuthenticated.value = store.getters['user/isAuthenticated'];
  authenticatedRedirect();
});
</script>
